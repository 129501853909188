import React, { useEffect } from "react"
import SEO from "../components/seo"

const Hjelp = () => {
  
  useEffect(()=>{

      window.$('.js-faq-toggle').on('click', function (e) {
        e.preventDefault();
        var $controls = window.$(this).attr('aria-controls');
        var $expanded = window.$(this).attr('aria-expanded');
        var $dialog = window.$('#' + $controls);
    
        $dialog.toggleClass('is-open');
    
        if ($expanded === 'true') {
          window.$(this).attr('aria-expanded', 'false');
        } else {
          window.$(this).attr('aria-expanded', 'true');
          $dialog.focus();
        }
    });

  },[])


  return (
  <React.Fragment>
    <SEO title="Lukekalender | Hjelp" />

    <a href="#main" title="Snarvei til hovedinnholdet" className="skip-navigation">Til innholdet</a>
    <div className="navigation">
        <div className="navigation__wrapper">
            <div className="navigation--logotype">
                <h1>
                    <a href="/">lukekalender.no</a>
                </h1>
            </div>
            <div className="navigation--nav">
                <nav id="navbar">
                    <ul>
                        <li><a href="/#bestill" className="order-button">Bestilling</a></li>
                        <li><a href="/#funksjoner">Funksjoner</a></li>
                        <li><a href="/#design">Design</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>

    <div className="hero-container hero-container--support">
      <div className="container">
          <div className="hero-container__wrapper">
              <div className="col wow fadeIn text-center">
                <h2 className="lead hero-container__subtitle">Hei, hvordan kan vi hjelpe deg?</h2>
                <p className="hero-container__desc">Våre rådgivere har flere års erfaring med vår digitale adventskalender. Du vil alltid få hjelp av noen som kjenner produktet.</p>
              </div>
          </div>
      </div>
  </div>

  <main className="main" id="main">

<section className="section section--regular">
  <div className="container">
    <div className="faq">
      <h2 className="faq__title" id="faq-title">Ofte stilte spørsmål / FAQ</h2>
      <div className="faq__row">
        <div className="faq__col">
          <ul className="faq__list" aria-label="faq-title">
            <li className="faq__item">
              <a href="#top" className="js-faq-toggle" role="button" aria-controls="faq-1" aria-expanded="false">Hvordan oppretter jeg en kalender?</a>
              <div className="faq__collapse" id="faq-1" role="dialog" tabindex="-1">
                <strong>Vi hjelper deg med å sette opp kalenderen du trenger.</strong>
                <p>Velg mellom julekalender, påskekalender/påskenøtter eller en generell kalender som kan brukes hvilken som helst måned over et fritt antall dager. </p>
                <p>Sammen skreddersyr vi også hvilken funksjonalitet du vil ha, slik at du får kalenderen akkurat slik du ønsker; blant annet videoluker og sponsorbilder.</p>
              </div>
            </li>
            <li className="faq__item">
              <a href="#top" className="js-faq-toggle" role="button" aria-controls="faq-2" aria-expanded="false">Hvordan legge inn spørsmål og svar?</a>
              <div className="faq__collapse" id="faq-2" role="dialog" tabindex="-1">
                <strong>Kalender &gt; Kalenderluker</strong>
                <p>Under fanen kalenderluker listes alle dagene i din kalender opp. Se etter riktig dag ved å følge kolonnen «Dato», deretter klikk «Rediger» i kolonnen «Spørsmål». «Rediger spørsmål» på mobil.</p>

                <strong>Kalender &gt; Kalenderluker &gt; Spørsmål &gt; Rediger</strong>
                <p>Velg tittel, spørsmål, svaralternativer og riktig alternativ, samt tittel og tekst for beskrivelse av premie. </p>
                <p>Premiebilde legges til i <strong>Kalender &gt; Kalenderluker</strong> i kolonnen «Premie». Her kan man legge til et premiebilde som vises når en deltaker har svart riktig, og et lenkemål man kan gå til ved å klikke på premiebildet.</p>
              </div>
            </li>
            <li className="faq__item">
              <a href="#top" className="js-faq-toggle" role="button" aria-controls="faq-3" aria-expanded="false">Hvordan sletter jeg en kalender?</a>
              <div className="faq__collapse" id="faq-3" role="dialog" tabindex="-1">
                <p>Vi kan fjerne en kalender veldig raskt, det er snakk om å fjerne én fil fra prosjektet. Men det er noe vi i Allegro må gjøre.</p>
                <p>Det blir en slags «soft delete» der man fortsatt kan se data i admin, men kalenderen vil ikke lenger kunne nås på et domene.</p>
              </div>
            </li>
            <li className="faq__item">
              <a href="#top" className="js-faq-toggle" role="button" aria-controls="faq-4" aria-expanded="false">Hvordan laster jeg opp mitt eget design?</a>
              <div className="faq__collapse" id="faq-4" role="dialog" tabindex="-1">
                <strong>Kalender &gt; Visuelle endringer</strong>
                <p>Her kan du endre design på lukene, inkludert fonter og farger for hovedoverskrift og ingress i kalenderen.</p>
                <p>I tillegg kan du endre oppstilling av logoen, legge til animert snø, og aktivere tilfeldig lukeplassering.</p>
                <strong>Kalender &gt; Bakgrunnsbilde</strong>
                <p>Velg bakgrunnsbilder til din kalender for alle enheter fra vårt bildearkiv, eller last opp dine egne bilder.</p>
                <strong>Kalender &gt; Logo</strong>
                <p>Her kan du laste opp ønsket logo til din kalender.</p>
                <p>Ta kontakt med oss dersom du har behov for flere endringer, et nytt design, eller har HTML og CSS som du ønsker å laste opp.</p>
              </div>
            </li>
            <li className="faq__item">
              <a href="#top" className="js-faq-toggle" role="button" aria-controls="faq-5" aria-expanded="false">Hvordan fungerer E-postpåminnelser?</a>
              <div className="faq__collapse" id="faq-5" role="dialog" tabindex="-1">
              <p>Alle som har svart på en luke, og meldt seg på til påminnelse, vil få en epost hver dag det kommer en ny luke – gjelder fra dagen etter at man har meldt seg på.
                Påminnelsene løper helt til kalenderperioden er over, eller de melder seg av, via en lenke de får i eposten.
                Eposten inneholder lenke til kalenderen med ferdig utfylte felter, og litt info om dagens luke.</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="faq__col">
          <ul className="faq__list" aria-label="faq-title">
            <li className="faq__item">
              <a href="#top" className="js-faq-toggle" role="button" aria-controls="faq-6" aria-expanded="false">Hvordan skaffe engasjement og høy deltakelse?</a>
              <div className="faq__collapse" id="faq-6" role="dialog" tabindex="-1">
                <p>
                  Det fins mange gode tiltak man kan gjøre for å skape trafikk til nettsidene. Et naturlig sted å begynne er å ta i bruk det nettverket dere allerede har.
                  Sosiale medier er utmerket til å skape oppmerksomhet rundt kalenderen.
                  Publiser poster både før og underveis, og sørg for å alltid linke til kalenderen.</p>

                  <p>Del vinnere (med deres tillatelse) og la deres nettverk spre kalenderen til enda flere deltagere.
                  For å utvide nedslagsfeltet kan det også være fornuftig å promotere postene og annonsere mot en målrettet kundegruppe.
                  Når noen først blir hekta er det stor sannsynlighet for at dere beholder oppmerksomheten deres ut perioden.</p>

                  <p>Det er vel anvendte kroner å annonsere for å øke deltagerantallet.
                  Det er viktig å huske at annonsene må stå i stil med lukekalenderen.
                  Dette skaper gjenkjennelse og bidrar til suksess. Dersom dere allerede har en nyhetsbrevliste, kan det være en god idé å sende til denne første dagen i kalenderen, med link til kalender-luka.</p>

                  <p>Glem heller ikke selve kalenderen. Sett sammen et godt design som treffer målgruppen. Her kan du velge mellom en av våre ferdige design, eller lage noe unikt.</p>
              </div>
            </li>
            <li className="faq__item">
              <a href="#top" className="js-faq-toggle" role="button" aria-controls="faq-7" aria-expanded="false">Hvordan trekkes en vinner?</a>
              <div className="faq__collapse" id="faq-7" role="dialog" tabindex="-1">
                <strong>Kalender &gt; Kalenderluker &gt; Svar</strong>
                <p>
                  Vinnere trekkes automatisk dagen etter og vises i bunnen av siden.
                  Dersom vi har aktivert manuell trekning av vinnere, kan dette gjøres ved å ved å klikke «Finn tilfeldig kandidat»
                </p>
                 
                <p>
                  Vinnere vises også i kalenderen.
                  Man kan skjule vinnere fra kalenderen ved å aktivere «Skjul visning av vinnere fra kalenderen» – dette gjøres i <strong>Kalender &gt; System > Logiske valg</strong>
                </p>

                <p>
                  Manuell vinner kan aktiveres. Men dette må gjøres av oss.
                  Da får man etter en endt dag muligheten til å trekke en tilfeldig vinner, og sette den personen som vinner av luken, eller trekke en gang til.
                  Man kan trekke en vinner helt til man har bekreftet at denne vinneren skal være dagens vinner. Da vises dette i kalenderen, hvis man har valgt å vise vinnere der.
                </p>

              </div>
            </li>
            <li className="faq__item">
              <a href="#top" className="js-faq-toggle" role="button" aria-controls="faq-8" aria-expanded="false">Hvilken statistikk er tilgjengelig for min kampanje?</a>
              <div className="faq__collapse" id="faq-8" role="dialog" tabindex="-1">
                <p>Antall svar/deltakere og informasjonen til deltakerne, i tillegg til all øvrig besøksstatistikk fra Google Analytics.</p>
              </div>
            </li>
            <li className="faq__item">
              <a href="#top" className="js-faq-toggle" role="button" aria-controls="faq-9" aria-expanded="false">Hvordan eksportere deltakere?</a>
              <div className="faq__collapse" id="faq-9" role="dialog" tabindex="-1">
                <strong>Kalender &gt; Kalenderluker &gt; Svar</strong>
                <p>Når deltakere har registrert seg i din kalender vil du kunne se antall svar i kolonnen «Svar». Klikk på «Vis», deretter «Eksporter liste over deltakere». Man kan også eksportere deltakere per dag.</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="section section--regular section--contact">
  <div className="container text-center">
    <h2>Finner du ikke det du leter etter? <br /> Vennligst kontakt en av våre rådgivere.</h2>
    <a href="mailto:post@allegro.no" className="button button--primary">Kontakt oss</a>
  </div>
</section>

</main>


  </React.Fragment>
)
}

export default Hjelp
